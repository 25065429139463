import React from 'react'
import styled, { keyframes } from 'styled-components'
import { respondTo, useShadow } from '../utils'

const Card = styled.div`
  border-radius: 10px 10px 4px 4px;
  padding-bottom: 0;
  margin-bottom: 1rem;

  background: ${({ theme }) => theme.white};
  box-shadow: ${useShadow('small')};
  list-style: none;

  ${respondTo.small`
    margin-bottom: 2rem;
  `}

  ${respondTo.medium`
    margin-bottom: 0;
  `}
`

const CardHeader = styled.div`
  top: 0;
  left: 0;
  display: block;
  padding: 1rem;
  padding-left: 2rem;
  width: 100%;
  font-weight: 500;
  font-size: 23px;
  border-radius: 1rem 1rem 0 0;
  background: ${props =>
    props.color ? props.theme[props.color] : props.theme['beige']};

  color: ${props =>
    props.color ? props.theme['white'] : props.theme.darkGray};
`

const bouncing = keyframes`
  0%, 10%, 30%, 50%, 100% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(10px);
  }
  40% {
    transform: translateX(5px);
  }
`

const ContentWrapper = styled.div`
  padding: 2rem;

  ul {
    padding: 0;
    font-size: 18px;
  }
  li {
    padding: 1rem 0px;
    & + li {
      border-top: 0.1px solid ${({ theme }) => theme.gray[300]};
    }
    p {
      padding: 0;
      a {
        padding: 0;
      }
    }
  }

  li > p > a {
    position: relative;
    display: block;
    display: flex;
    justify-content: space-between;

    :after {
      content: '>';
      font-weight: bold;
      color: ${({ theme }) => theme.magenta};
    }

    :hover {
      :after {
        animation: ${bouncing} 1.5s;
      }
    }
  }

  li:first-child > p > a {
    padding-top: 0px;
  }

  li:last-child > p > a {
    padding-bottom: 0px;
  }
`

const LinksCard = ({ title, children, index }) => {
  return (
    <Card>
      <CardHeader color={['blue', 'magenta', null][index % 3]}>
        {title}
      </CardHeader>
      <ContentWrapper>{children}</ContentWrapper>
    </Card>
  )
}

export default LinksCard
