import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { respondTo, useBorderRadius } from '../utils'

const ImageLink = styled(Link)`
  display: block;
  border-radius: ${useBorderRadius('default')};
  position: relative;
  display: flex;
  overflow: hidden;
  z-index: ${({ theme }) => theme.layers[0]};

  ${respondTo.small`
    margin-top: 0;
  `}

  .scale-transform {
    will-change: transform;
    transition: transform 0.5s ease-in-out;
    transform-origin: center center;
  }

  :hover .scale-transform {
    transform: scale(1.1);
  }
`
const ImageBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`

const Content = styled.div`
  align-self: flex-end;
  padding: 2rem 2rem 1rem 2rem;
  color: white;
`

const Heading = styled.p`
  font: 1.9rem/1.5 bold neo-sans, sans-serif;
  margin: 0;
`

const ImageCardLink = ({ label, heading, slug, image, ...rest }) => {
  return (
    <ImageLink to={slug} {...rest}>
      <ImageBackground>
        <Img
          fluid={image.fluid}
          style={{ position: 'absolute', width: '100%', height: '100%' }}
          className="scale-transform"
        />
      </ImageBackground>
      <Content>
        <Heading>{heading}</Heading>
      </Content>
    </ImageLink>
  )
}

export default ImageCardLink
