/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React from 'react'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => (
      <a href={node.data.uri} target="_blank" rel="noopener">
        {children}
      </a>
    ),
  },
}

const RichTextRenderer = document =>
  documentToReactComponents(document, options)

export default RichTextRenderer
