import React from 'react'
import styled from 'styled-components'
import { respondTo } from '../utils'

const Wrapper = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.lightBeige};

  ${respondTo.small`
    padding: 2rem;
    flex-direction: row;
  `}

  ${respondTo.medium`
    padding: 5rem;
    justify-content: space-around;
  `}
`

const HeroSection = ({ children }) => {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  )
}

export default HeroSection