import React from 'react'
import { graphql } from 'gatsby'
import DebugOptions, { DebugButton } from '../utils/debugOptions'
import Layout from '../layouts'
//components
import PageSection from '../components/PageSection'
import HeroSection from '../components/HeroSection'
import Search from '../components/search/'
import LinksCard from '../components/LinksCard'
import RichTextRenderer from '../utils/richTextRenderer'
import theme from '../theme'

//styled
import { Wrapper, CallToAction } from '../styledComponents'
import ImageCardLink from '../components/ImageCardLink'

const RootIndex = ({ data }) => {
  return (
    <Layout pageTitle="Labgids">
      <Wrapper>
        {process.env.NODE_ENV === 'development' && (
          <DebugOptions>
            <DebugButton onClick={() => window.localStorage.clear()}>
              Clear LS
            </DebugButton>
          </DebugOptions>
        )}
        <HeroSection>
          <CallToAction>
            <p>
              Welkom in de labgids van het Deventer Ziekenhuis. Deze labgids
              bevat actuele informatie over de aanvraag en interpretatie van
              >1000 testen die door de laboratoria van het Deventer Ziekenhuis
              worden aangeboden.
            </p>
            <Search />
          </CallToAction>
          <ImageCardLink {...data.catalogLink} style={{ flex: '0 0 25%' }} />
        </HeroSection>
        <PageSection flex={3} justify="space-between" noSubtitle>
          {data.homepage.informatieblokken.map((node, index) => (
            <LinksCard title={node.titel} index={index}>
              {RichTextRenderer(node.inhoud.json)}
            </LinksCard>
          ))}
        </PageSection>
      </Wrapper>
    </Layout>
  )
}
export default RootIndex

export const pageQuery = graphql`
  {
    homepage: contentfulHomepage(pageType: { eq: "homepage" }) {
      pageType
      informatieblokken {
        titel
        inhoud {
          json
        }
      }
    }
    info: allContentfulInformatieBlok {
      nodes {
        titel
        inhoud {
          json
        }
      }
    }
    catalogLink: contentfulLinkMetAfbeelding {
      label
      heading
      slug
      image {
        fluid(maxWidth: 720, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`
